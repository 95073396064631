<template>
  <div class="mt-4">
    <v-alert v-if="request.status == 'pending'" color="warning">
      <div v-if="user.id !== request.responsibleId">
        <v-icon left> mdi-alert </v-icon>
        <span class="font-weight-bold">Atenção:</span> Este serviço ainda não foi aceito pelo prestador.
      </div>
      <div v-else class="d-flex gap-2 align-center">
        <v-icon left> mdi-alert </v-icon>
        <div class="flex-grow-1">
          <span class="font-weight-bold">Atenção:</span> Este serviço ainda não foi aceito por você
          <v-btn small block @click="answer">Responder</v-btn>
        </div>
      </div>
    </v-alert>
    <v-alert v-if="request.status == 'accepted' && user.id === request.responsibleId" color="info">
      <div class="d-flex gap-2 align-center">
        <v-icon> mdi-wrench </v-icon>
        Iniciar serviço
        <v-spacer />
        <v-btn small @click="start" :loading="loadingStart">Iniciar</v-btn>
      </div>
    </v-alert>
    <v-alert v-if="request.status == 'inProgress' && user.id === request.responsibleId" color="warning">
      <div class="d-flex gap-2">
        <v-icon> mdi-wrench </v-icon>
        <div class="d-flex align-center flex-wrap gap-1 flex-grow-1">
          <span class="font-weight-bold">Servico em andamento: </span>
          <span>{{ [startLog.createdAt] | dateDiff(true) }}</span>

          <v-spacer />
          <v-btn small @click="finish" :loading="loadingStart">Finalizar</v-btn>
        </div>
      </div>
    </v-alert>
    <AnswerModal v-if="request.status == 'pending'" :request="request" :loading="loading" @success="$emit('refresh')" />
    <FinishModal v-if="request.status == 'inProgress'" :request="request" :loading="loading" @success="$emit('refresh')" />
  </div>
</template>
<script>
import serviceStatus from '@/utils/serviceStatus'
import { mapGetters } from 'vuex'
import AnswerModal from './modal/ServiceRequestAnswerModal.vue'
import FinishModal from './modal/ServiceRequestFinishModal.vue'
import SERVICES from '@/services/services'

export default {
  data: () => ({
    serviceStatus,
    loadingStart: false,
  }),
  methods: {
    answer() {
      this.$emit('answer')
    },
    finish() {
      this.$emit('finish')
    },
    async start() {
      try {
        this.loadingStart = true
        await SERVICES.request.start(this.request.id)
        this.$emit('refresh')
      } catch (error) {
        this.$emit('error', error.message || error)
      } finally {
        this.loadingStart = false
      }
    },

  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'user']),
    startLog() {
      return this.request.ServiceRequestLog.find(log => log.action === 'start')
    },
  },
  components: {
    AnswerModal,
    FinishModal,
  },
  props: {
    loading: Boolean,
    request: Object,
  },
}
</script>
