export default {
  pending: {
    icon: 'mdi-clock-outline',
    color: 'warning',
    text: 'Pendente',
  },
  refused: {
    icon: 'mdi-close-circle-outline',
    color: 'error',
    text: 'Recusado',
  },
  accepted: {
    icon: 'mdi-check-circle-outline',
    color: 'info',
    text: 'Aceito',
  },
  inProgress: {
    icon: 'mdi-clock-outline',
    color: 'warning',
    text: 'Em andamento',
  },
  done: {
    icon: 'mdi-check-circle-outline',
    color: 'success',
    text: 'Concluído',
  },
  canceled: {
    icon: 'mdi-close-circle-outline',
    color: 'secondary',
    text: 'Cancelado',
  },
}