<template>
  <v-dialog v-model="dialog" max-width="500px" scrollable hide-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">Responder Solicitação de Serviço</span>
      </v-card-title>

      <v-card-text>
        <v-btn-toggle v-model="accepted" class="w-full" :color="accepted ? 'success' : 'error'">
          <v-btn class="flex-grow-1" :value="true">
            <v-icon v-if="accepted" small left color="success">mdi-check</v-icon>
            Aceitar
          </v-btn>
          <v-btn class="flex-grow-1" :value="false">
            <v-icon v-if="!accepted" small left color="error">mdi-close</v-icon>
            Recusar
          </v-btn>
        </v-btn-toggle>

        <v-textarea
          v-model="reason"
          v-if="!accepted"
          :rules="[v => !!v || 'Campo obrigatório', v => v.length <= 255 || 'Máximo de 255 caracteres', v => v.length >= 10 || 'Mínimo de 10 caracteres']"
          label="Motivo da recusa"
          outlined
          rows="3"
          class="mt-4"
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-btn color="primary" :disabled="loading" class="mr-2 mt-2" outlined @click="close">Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!valid" :loading="loading" class="mt-2" @click="save">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SERVICES from '@/services/services'

export default {
  data() {
    return {
      menu: false,
      loading: false,
      dialog: false,
      accepted: true,
      reason: '',
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        await SERVICES.request.answer(this.request.id, {
          accepted: this.accepted,
          reason: this.accepted ? undefined : this.reason,
        })

        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open() {
      this.accepted = true
      this.reason = ''
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
  computed: {
    valid() {
      return this.accepted || this.reason.length >= 10
    },
  },
  props: {
    request: Object,
  },
  created() {
    this.$parent.$on('answer', this.open)
  },
}
</script>

<style></style>
