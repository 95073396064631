<template>
  <div class="mt-4 d-flex flex-column gap-4">
    <div class="d-flex align-center justify-space-between">
      <h3 class="font-weight-bold mb-0">Histórico</h3>
      <div class="d-flex gap-2" v-if="!isExport">
        <v-btn
          v-if="!['canceled', 'pending', 'refused'].includes(request.status)"
          @click="addMessage"
          color="primary"
          small
        >
          <v-icon left>mdi-plus</v-icon> mensagem
        </v-btn>
        <v-btn
          @click="exportPDF()"
          v-if="!['canceled', 'pending', 'refused'].includes(request.status)"
          color="primary"
          small
        >
          <v-icon left>mdi-file-export</v-icon> exportar
        </v-btn>
      </div>
    </div>
    <p v-if="!request.ServiceRequestLog.length">Nenhum registro encontrado</p>
    <v-card outlined class="pa-3 d-flex gap-3" v-for="log in request.ServiceRequestLog" :key="log.id">
      <div class="d-flex flex-column align-center" style="min-width: 60px">
        <v-icon large>{{ serviceLogType[log.action].icon }}</v-icon>
        <small>
          {{ serviceLogType[log.action].text }}
        </small>
      </div>
      <v-divider vertical></v-divider>
      <div class="flex-grow-1">
        <div class="d-flex justify-space-between">
          <span class="font-weight-bold">{{ log.CreatedBy.name }}</span>
          <small>{{ log.createdAt | date('DD/MM/YYYY HH:mm') }}</small>
        </div>
        {{ log.description }}
        <div v-if="(log.files || []).length" class="d-flex flex-wrap mt-2 gap-x-2 gap-y-1">
          <div v-for="(file, index) in log.files" :key="index">
            <div v-if="!isExport">
              <v-chip outlined :href="file.url" target="_blank" style="max-width: 190px">
                <v-icon left small>{{ file.name | fileIcon }}</v-icon>
                <p class="mb-0 text-truncate">{{ file.name }}</p>
              </v-chip>
            </div>
            <div v-else>
              <v-img :src="file?.url" style="width: 40px; height: 40px" />
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <ServiceRequestMessageModal :request="request" :loading="loading" @success="$emit('refresh')" />
    <ServicePDF :request="request" ref="exportPdf" name="exportPdf" />
  </div>
</template>
<script>
import serviceLogType from '@/utils/serviceLogType'
import ServicePDF from '@/components/services/ServicePDF'
import { mapGetters } from 'vuex'
import ServiceRequestMessageModal from './modal/ServiceRequestMessageModal.vue'

export default {
  name: 'ServiceLog',
  data: () => ({
    serviceLogType,
  }),
  methods: {
    addMessage() {
      this.$emit('addMessage')
    },
    exportPDF() {
      if (this.$refs.exportPdf && typeof this.$refs.exportPdf.downloadPDF === 'function') {
        this.$refs.exportPdf.downloadPDF();
      } else {
        console.error('Método downloadPDF não encontrado');
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'user']),
  },
  components: { ServiceRequestMessageModal, ServicePDF },
  props: {
    loading: Boolean,
    request: Object,
    isExport: Boolean,
  },
}
</script>
