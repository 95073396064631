<template>
  <v-dialog v-model="dialog" max-width="500px" scrollable hide-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">Mensagem</span>
      </v-card-title>

      <v-card-text>
        <v-textarea
          v-model="message"
          :rules="[
            v => !!v || 'Campo obrigatório',
            v => v.length <= 255 || 'Máximo de 255 caracteres',
            v => v.length >= 10 || 'Mínimo de 10 caracteres',
          ]"
          label="Mensagem"
          outlined
          rows="5"
          class="mt-1"
        ></v-textarea>

        <div class="d-flex gap-1 flex-wrap">
          <v-card v-for="(file, index) in files" :key="index" outlined style="max-width: 190px" class="pa-3 pr-1">
            <div class="d-flex align-center h-full gap-2">
              <div class="d-flex flex-grow-1 gap-2">
                <v-icon size="24">{{ file.name | fileIcon }}</v-icon>
                <div class="text-truncate" style="max-width: 100px">{{ file.name }}</div>
              </div>
              <v-btn @click="files.splice(index, 1)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
          <v-card @click="selectFile" outlined>
            <v-card-text class="text-center">
              <v-icon size="24">mdi-plus</v-icon>
              <div>Adicionar arquivo</div>
            </v-card-text>
          </v-card>
        </div>
        <input type="file" ref="file" @change="addFile" style="display: none" />
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-btn color="primary" :disabled="loading" class="mr-2 mt-2" outlined @click="close">Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!valid" :loading="loading" class="mt-2" @click="save">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SERVICES from '@/services/services'

export default {
  data() {
    return {
      menu: false,
      loading: false,
      dialog: false,
      message: '',
      files: [],
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        const { message } = await SERVICES.request.message(this.request.id, {
          message: this.message,
        })

        for (const file of this.files) {
          await SERVICES.request.messageUpload(this.request.id, message.id, file)
        }

        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    selectFile() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    addFile(event) {
      const files = event.target.files
      this.files.push(...files)
    },
    open() {
      this.message = ''
      this.files = []
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
  computed: {
    valid() {
      return this.message.length
    },
  },
  props: {
    request: Object,
  },
  created() {
    this.$parent.$on('addMessage', this.open)
  },
}
</script>

<style></style>
