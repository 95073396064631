export default {
  message: {
    icon: 'mdi-message',
    color: '',
    text: 'Mensagem',
  },
  accept: {
    icon: 'mdi-check-circle-outline',
    color: 'success',
    text: 'Aceito',
  },
  refuse: {
    icon: 'mdi-close-circle-outline',
    color: 'error',
    text: 'Recusado',
  },
  start: {
    icon: 'mdi-clock-outline',
    color: 'info',
    text: 'Iniciado',
  },
  done: {
    icon: 'mdi-check-circle-outline',
    color: 'success',
    text: 'Finalizado',
  },
  cancel: {
    icon: 'mdi-close-circle-outline',
    color: 'error',
    text: 'Cancelado',
  },
}