<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="request.Service.name"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width=""
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section class="v-application" slot="pdf-content">
        <div class="px-3">
          <v-card light color="white" outlined>
            <v-card-title class="d-flex gap-x-2 gap-y-2 align-center">
              <h5>{{ request.Service.name }}</h5>
              <v-chip class="pa-1" label :color="serviceStatus[request.status].color" small>
                <v-icon x-small class="pr-1">{{ serviceStatus[request.status].icon }}</v-icon>
                <span class="white--text"> {{ serviceStatus[request.status].text }}</span>
              </v-chip>
              <v-spacer />
              <small>
                <v-icon small> mdi-calendar-end </v-icon>
                {{ request.deadline | date }}
              </small>
            </v-card-title>
            <div class="outlinedDiv">
              <div color="white">
                <span class="d-block pl-3 font-weight-bold">Descrição</span>
                <span class="pl-3">{{ request.description }}</span>
              </div>

              <div class="d-flex flex-wrap gap-x-4 gap-y-1 mt-4 justify-space-around">
                <div>
                  <v-icon small left> mdi-account </v-icon>
                  <span class="font-weight-bold">Solicitado por:</span>
                  {{ request.RequiredBy.name }}
                </div>
                <div>
                  <v-icon small left> mdi-account-wrench </v-icon>
                  <span class="font-weight-bold">Responsável:</span>
                  {{ request.Responsible.name }}
                </div>
                <div>
                  <v-icon small left> mdi-calendar </v-icon>
                  <span class="font-weight-bold">Solicitado em:</span>
                  {{ request.createdAt | date }}
                </div>
                <div v-if="isExport">
                  <v-icon small left> mdi-calendar </v-icon>
                  <span class="font-weight-bold">Endereço:</span>
                  {{ request.Immobile.Address.street }}, {{ request.Immobile.Address.number }} -
                  {{ request.Immobile.Address.city }} - {{ request.Immobile.Address.state }}
                </div>
              </div>
            </div>
          </v-card>
          <div class="d-flex flex-column gap-4">
            <div class="d-flex align-center justify-space-between">
              <h3 class="font-weight-bold mb-0 pt-4 pl-5">Histórico</h3>
              <div class="d-flex gap-2" v-if="!isExport">
                <v-btn
                  @click="exportPDF()"
                  v-if="!['canceled', 'pending', 'refused'].includes(request.status)"
                  color="primary"
                  small
                >
                  <v-icon left>mdi-file-export</v-icon> exportar
                </v-btn>
              </div>
            </div>
            <p v-if="!request.ServiceRequestLog.length">Nenhum registro encontrado</p>
            <v-card color="white" v-for="log in request.ServiceRequestLog" :key="log.id">
              <div class="d-flex pa-3 gap-2 outlinedDiv">
                <div class="d-flex flex-column align-center" style="min-width: 90px">
                  <v-icon style="color: #838584" large>{{ serviceLogType[log.action].icon }}</v-icon>
                  <small>
                    {{ serviceLogType[log.action].text }}
                  </small>
                </div>
                <div class="divider"></div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold">{{ log.CreatedBy.name }}</span>
                    <small>{{ log.createdAt | date('DD/MM/YYYY HH:mm') }}</small>
                  </div>
                  {{ log.description }}
                  <div v-if="(log.files || []).length" class="d-flex flex-wrap mt-2 gap-x-2 gap-y-1">
                    <div v-for="(file, index) in log.files" :key="index">
                      <div>
                        <v-chip outlined :href="file.url" target="_blank" style="max-width: 190px">
                          <v-icon left small>{{ file.name | fileIcon }}</v-icon>
                          <p class="mb-0 text-truncate">{{ file.name }}</p>
                        </v-chip>
                      </div>
                      <div>
                        <img :src="file?.url" style="width: 40px; height: 40px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import ServiceHeader from './ServiceHeader.vue'
import ServiceLog from './ServiceLog.vue'
import serviceLogType from '@/utils/serviceLogType'
import serviceStatus from '@/utils/serviceStatus'

export default {
  name: 'ServicePDF',
  data() {
    return {
      isExport: true,
      serviceLogType,
      serviceStatus,
    }
  },
  methods: {
    async downloadPDF() {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(progress) {
      console.log('Progress: ', progress)
    },
    hasStartedGeneration() {
      console.log('hasStartedGeneration')
    },
    hasGenerated(pdf) {
      console.log('hasGenerated', pdf)
    },
  },
  components: {
    VueHtml2pdf,
    ServiceHeader,
    ServiceLog,
  },
  props: {
    request: Object,
  },
}
</script>

<style>
.outlinedDiv {
  border: thin solid rgba(17, 20, 21, 0.14) !important;
  border-radius: 4px;
}

.divider {
  width: 1px;
  height: 100px;
  background-color: #000;
  margin: 0 10px;
}
</style>
