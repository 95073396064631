var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","hide-overlay":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Finalizar Solicitação de Serviço")])]),_c('v-card-text',[_c('v-textarea',{staticClass:"mt-1",attrs:{"rules":[
          v => !!v || 'Campo obrigatório',
          v => v.length <= 255 || 'Máximo de 255 caracteres',
          v => v.length >= 10 || 'Mínimo de 10 caracteres',
        ],"label":"Descrição","outlined":"","rows":"5"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"d-flex gap-1 flex-wrap"},[_vm._l((_vm.files),function(file,index){return _c('v-card',{key:index,staticClass:"pa-3 pr-1",staticStyle:{"max-width":"190px"},attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center h-full gap-2"},[_c('div',{staticClass:"d-flex flex-grow-1 gap-2"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(_vm._s(_vm._f("fileIcon")(file.name)))]),_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(_vm._s(file.name))])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.files.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])}),_c('v-card',{style:({
            borderColor: _vm.files.length == 0 ? '#f4575d' : undefined,
            color: _vm.files.length == 0 ? '#f4575d' : undefined,
          }),attrs:{"outlined":""},on:{"click":_vm.selectFile}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("mdi-plus")]),_c('div',[_vm._v("Adicionar arquivo")])],1)],1)],2),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.addFile}})],1),_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"color":"primary","disabled":_vm.loading,"outlined":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Finalizar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }