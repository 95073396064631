<template>
  <div class="d-flex flex-column gap-4">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <div v-if="request">
      <ServiceHeader :loading="loading" :request="request" />
      <ServiceBody :loading="loading" :request="request" @refresh="getData" />
  <ServiceLog :loading="loading" :request="request" @refresh="getData" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SERVICES from '@/services/services'
import ServiceHeader from '@/components/services/ServiceHeader.vue'
import ServiceBody from '@/components/services/ServiceBody.vue'
import ServiceLog from '@/components/services/ServiceLog.vue'

export default {
  name: 'Services',
  data() {
    return {
      request: null,
      loading: false,
      error: null,
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        this.error = null
        const { request } = await SERVICES.request.byId(this.$route.params.id)
        this.request = request
      } catch (error) {
        this.error = error.message || error
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  components: { ServiceHeader, ServiceBody, ServiceLog },
}
</script>
